import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders, replaceInFilter } from 'app/services/commons';
import { AppConfig } from 'app/app.config';
import { saveAs } from 'file-saver';

let _frequenceDataSource = [
  { value: 0, label: "NON_DEFINI" },
  { value: 1, label: "QUOTIDIEN" },
  { value: 2, label: "HEBDOMADAIRE" },
  { value: 3, label: "MENSUEL" },
  { value: 4, label: "BIMENSUEL" },
  { value: 5, label: "TRIMESTRIEL" },
  { value: 6, label: "SEMESTRIEL" }
]

@Injectable({ providedIn: 'root' })
export class ClientService extends CustomStore {

  private http2: HttpClient;
  private dataSource: any;
  public archive: boolean = false;

  get isArchive() { return this.archive; }
  set setArchive(v: boolean) { this.archive = v; }

  public fastMode: any = [1];





  importCS(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/client/importCS", values, { headers: getHttpHeaders() }).toPromise();
  }


  importS(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/client/importS", values, { headers: getHttpHeaders() }).toPromise();
  }

  importUA(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/client/importUA", values, { headers: getHttpHeaders() }).toPromise();
  }
  mergeUA(values: any) {
    return this.http.post<any>(AppConfig.settings.api + "/api/client/mergeUA", values, { headers: getHttpHeaders() }).toPromise();
  }


  exportUA(groupe_id: any, colonne: boolean) {
   return this.http.get(AppConfig.settings.api + "/api/client/exportUA/" + groupe_id  + "?colonne="+colonne, { headers: getHttpHeaders(), responseType: 'blob' }
   ).toPromise().then(response => {
     saveAs(response, "listeUtilisateurs" + groupe_id + ".xlsx");
     notify("La liste a été exportée.");
   }).catch(error => {
     console.log("ERROR=", error);
   });

  }


  getAdministrateurReferentFromSite(site_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/administrateur/site/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }


  getPack5QStatus(client_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/pack5q/" + encodeURIComponent(client_id), { headers: getHttpHeaders() }).toPromise();
  }


  // migrationGroupes( ) {
  //   return this.http.get<any>(AppConfig.settings.api + "/api/client/migrationgroupes/" , { headers: getHttpHeaders() }).toPromise();
  // }


  colporteDatesModel(client_id: any, mode: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/colportedates/" + encodeURIComponent(client_id) + "?mode=" + mode, { headers: getHttpHeaders() }).toPromise();
  }


  sortirDuGroupe(client_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/sortirdugroupe/" + encodeURIComponent(client_id), { headers: getHttpHeaders() }).toPromise();
  }


  encapsulerDansNouveauGroupe(client_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/encapsulerdansnouveaugroupe/" + encodeURIComponent(client_id), { headers: getHttpHeaders() }).toPromise();
  }



  diagnoseForClientOrDivisionToBeMoved(clientids: any, targetgroupe: any) {
    // Mettre à jour ou créer (pris en charge côté serveur)
    return this.http.get<any>(AppConfig.settings.api + "/api/client/movedivisiontogroupe/diagnose/" + encodeURIComponent(clientids) + "/" + targetgroupe, { headers: getHttpHeaders() }).toPromise();
  }
  moveClientOrDivisionToGroup(clientids: any, targetgroup: any, options: any) {
    // Mettre à jour ou créer (pris en charge côté serveur)
    return this.http.put<any>(AppConfig.settings.api + "/api/client/movedivisiontogroupe/" + encodeURIComponent(clientids) + "/" + targetgroup, options, { headers: getHttpHeaders() }).toPromise();
  }

  getClientsFromPartenaire(partne_id: any) {
    return this.http.get<any>(AppConfig.settings.api + "/api/client/partenaire/" + encodeURIComponent(partne_id), { headers: getHttpHeaders() }).toPromise();
  }



  constructor(private http: HttpClient) {
    super();
    this.http2 = http;
    let SERVICE_URL = AppConfig.settings.api + "/api/client/";
    var fastMode = this.fastMode;

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any): any {
          var URL = SERVICE_URL;
          let filter = "";

          if (loadOptions.filter) {
            replaceInFilter(loadOptions.filter, "nom", "n.nom");
            replaceInFilter(loadOptions.filter, "groupe", "n.groupe.nom");
            replaceInFilter(loadOptions.filter, "email", "n.email");
            replaceInFilter(loadOptions.filter, "telephone", "n.telephone");
            replaceInFilter(loadOptions.filter, "partenaire", "n.partenaire");
            replaceInFilter(loadOptions.filter, "nomClientEtendu", "n.nom");
            // User search
            replaceInFilter(loadOptions.filter, "activite", "n.activite");
            replaceInFilter(loadOptions.filter, "notes", "n.notes");
            replaceInFilter(loadOptions.filter, "notesFormations", "n.notesFormations");
            replaceInFilter(loadOptions.filter, "notesAudits", "n.notesAudits");
            replaceInFilter(loadOptions.filter, "notesBilans", "n.notesBilans");

            if (AppConfig.settings.trace) {
              console.log("Client loadOptions=", loadOptions);
              console.log("Client FILTER=", loadOptions.filter);
              console.log("Client FILTER_SQL=", jsToSQLFilter(loadOptions.filter));
            }
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));

            // Cas de l'utilisation du filtrage
            if (loadOptions.filter[0]["columnIndex"]) {
              filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));


            }

            else
              // On passe en fast mode seulement si une chaine a aété saisie dans le champ de recherche
              if (fastMode[0] && loadOptions.filter[0][0] == "groupe.id") {

              }
              else
                if (fastMode[0] && loadOptions.filter[0] != "n.archive") {
                  URL = SERVICE_URL + "fast/";
                  if (loadOptions.filter.length == 3) {
                    //  var F = [["archive", "=", loadOptions.filter[2][2] ], "and", ["n.nom", "contains", loadOptions.filter[0][0][2]] ];
                    var F = undefined;
                    if (loadOptions.filter[2][0] == "archive" || loadOptions.filter[2][0] == "n.archive")
                      F = [["n.archive", "=", ( (loadOptions.filter[2][2]=='1'||loadOptions.filter[2][2]==1) ? true : false)], "and", [["n.nom", "contains", loadOptions.filter[0][0][2]], "OR", ["n.groupe.nom", "contains", loadOptions.filter[0][0][2]]]];
                    else
                      F = [["n.archive", "=", false], "and", [["n.nom", "contains", loadOptions.filter.filterValue], "OR", ["n.groupe.nom", "contains", loadOptions.filter.filterValue]]]; // 

                    filter = "?filter=" + encodeURIComponent(jsToSQLFilter(F));
                  }
                }
          }
          else
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(["n.archive", "=", false]));




          if (loadOptions.skip) {
            filter = filter + "&skip=" + loadOptions.skip;
          }
          else {
            filter = filter + "&skip=0";
          }

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = "";
              if (loadOptions.sort[i].selector == "nom")
                field = "n.nom";
              else
                if (loadOptions.sort[i].selector == "nomClientEtendu")
                  field = "n.nom";
                else
                  if (loadOptions.sort[i].selector == "modelDateDebut")
                    field = "n.modelDateDebut";
                  else
                    if (loadOptions.sort[i].selector == "activite")
                      field = "n.activite";
                    else
                      if (loadOptions.sort[i].selector == "email")
                        field = "n.email";
                      else
                        if (loadOptions.sort[i].selector == "effectif")
                          field = "n.effectif";
                        else
                          if (loadOptions.sort[i].selector == "prendreEffectif")
                            field = "n.prendreEffectif";
                          else
                            field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }
          if (AppConfig.settings.trace)
            console.log("---------------> Client SERVER CALL: " + URL + filter);

          return http.get<any>(URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if (AppConfig.settings.trace)
                console.log("---------------> Client SERVER RESPONSE: ", response);
              return response;
            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {

          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },


        insert: function (values: any) {
          if (AppConfig.settings.trace) console.log("CREATE client: values=", values);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },


        update: function (key, values) {
          if (AppConfig.settings.trace) console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
          if (AppConfig.settings.trace) console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {
            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          });
        },



        remove: function (key) {

          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }

    );

  }




  getRestDataSource() {
    return this.dataSource;
  }


  getFrequenceDataSource() {
    return _frequenceDataSource;
  }

}
