
import { Component, OnInit, EventEmitter, Output, Input, ViewChild,  SimpleChanges } from '@angular/core';
import { DxDataGridComponent, DxDropDownBoxComponent } from "devextreme-angular";
import { SiteService } from 'app/services/site.service';
import { TranslateService } from '@ngx-translate/core';
import { QSE_LAST_LANGUE } from 'app/services/user.service';

@Component({
  selector: 'app-site-selecteur',
  templateUrl: './site-selecteur.component.html',
  styleUrls: ['./site-selecteur.component.scss']
})
export class SiteSelecteurComponent implements OnInit {


  @ViewChild('dropBox') dropBox: DxDropDownBoxComponent;
  @ViewChild('siteGrid') siteGrid: DxDataGridComponent;
  @Output() siteUpdated = new EventEmitter();
  @Input('selectedClient') selectedClient: any = undefined;
  @Input('selectedSite') selectedSite: any = undefined;
  @Input('profilFilter') profilFilter: any = undefined;
  @Input('defaultMessage') nomSite: any = "Choisir un site";


  // datasources
  siteDataSource: any;



  _gridBoxValue: number = 1;
  _gridSelectedRowKeys: number[] = [1];



  constructor(private siteService: SiteService, public translate: TranslateService) {
    this.siteDataSource = siteService.getRestDataSource();

    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    this.translate.get(
      [
        'site-selecteur-placeholder*nomSite'

      ]
    )
      .subscribe(val => {

      this.nomSite=val['site-selecteur-placeholder*nomSite'];

      });

  }

  ngOnInit() {
    this.translate.use(sessionStorage.getItem(QSE_LAST_LANGUE));
    if (this.selectedClient == 0) this.selectedClient = 0;
    if (this.selectedSite == 0) this.selectedSite = undefined;
    //  console.log("site-selecteur.component : ngOnInit()");
  }


  reset() {
    this.selectedSite = undefined;
  }


  ngAfterViewInit() {
    // Trick for enabling site refreshing when changing client for the very first time
    this.dropBox.instance.open();
    this.dropBox.instance.close();

  }


  public update() {
    if (!this.profilFilter)
      this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", ["actif", "=", true]]);
    else
      this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", [["profil.id", "=", this.profilFilter], "OR", ["profil.id", "IS", "NULL"]],  "AND", ["actif", "=", true]]);

    this.siteGrid.instance.getDataSource().reload();
  }

  ngOnChanges(changes: SimpleChanges) {
//    console.log("siteSelecteur:ONCHANGE:", changes);
    //const client: SimpleChange = changes.selectedClient;
    this.nomSite="";
    
    if (changes.selectedClient) {

      if (this.siteGrid && this.siteGrid.instance) {
        if (!this.profilFilter)
        this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", ["actif", "=", true]]);
        else
          this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", [["profil.id", "=", this.profilFilter], "OR", ["profil.id", "IS", "NULL"]],"AND", ["actif", "=", true]]);

        this.siteGrid.instance.getDataSource().reload();
        // this.siteDataSource.filter("client_id", "=", this.selectedClient);

        // sélectionner le premier site par défaut ?
      }
    }

    if (changes.profilFilter) {

      if (this.siteGrid && this.siteGrid.instance) {
        if (!this.profilFilter)
        this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", ["actif", "=", true]]);
        else
        this.siteGrid.instance.filter([["client.id", "=", this.selectedClient], "AND", [["profil.id", "=", this.profilFilter], "OR", ["profil.id", "IS", "NULL"]],"AND", ["actif", "=", true]]);

        this.siteGrid.instance.getDataSource().reload();
        // this.siteDataSource.filter("client_id", "=", this.selectedClient);

        // sélectionner le premier site par défaut ?
      }
    }

    if (changes.defaultMessage)
      this.nomSite = changes.defaultMessage;

  }





  dropdown_syncGridSelection(event) {

    if (!this.siteGrid) return;

    if (event.value === null) {
      console.log("CLEARED !!!");
    }
    if (!this.siteGrid) {
      this.siteGrid.instance.clearSelection();
    } else {
      // If this is not commented, then selection is sysematically cleared
      ;// this.selectTreeList.instance.selectRowsByIndexes(this.treeListValue);
    }
  }

  dropdown_gridItemSelectionChanged(event) {
    if (!this.siteGrid) return;
    // console.log("dropdown_gridItemSelectionChanged", event);
    this.selectedSite = this.siteGrid.instance.getSelectedRowKeys();

    this._gridBoxValue = this.selectedSite.length && this.selectedSite[0] || null;
    this._gridSelectedRowKeys = this.selectedClient;

    if (this.dropBox && this.dropBox.instance && event.selectedRowKeys.length > 0) {
      this.dropBox.instance.close();
      this.nomSite = event.selectedRowsData[0].nom;
    }
    this.siteUpdated.emit(this.siteGrid.instance.getSelectedRowsData()[0]);
  }


  get gridBoxValue(): number {
    return this._gridBoxValue;
  }

  set gridBoxValue(value: number) {
    this._gridSelectedRowKeys = value && [value] || [];
    this._gridBoxValue = value;
  }

  get gridSelectedRowKeys(): number[] {
    return this._gridSelectedRowKeys;
  }

  set gridSelectedRowKeys(value: number[]) {
    this._gridBoxValue = value && value.length && value[0] || null;
    this._gridSelectedRowKeys = value;
  }

  gridBox_displayExpr(item) {
    return item && item.nom;
  }

}
