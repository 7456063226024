  <dx-form #eeForm_, id="eeForm" [(formData)]="eeModel" showColonAfterLabel="false" onDataFieldChange="onDataFieldChange($event, eeForm)" [colCount]="1"
          [disabled]="disabled || internalDisabled">

    <dxi-item>
      <div *dxTemplate>
      <app-ee-status [eeModelId]="eeModel.id" [(statut)]="eeModel.statut" [minimal]="minimal" (onValueChangedAction)="onStatusValueChanged($event)"
                     [popUpContainer]="popUpContainer"></app-ee-status> 
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isToEvaluate()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-to-evaluate [(model)]="eeModel.toEvaluateModel"
                            [formAttr]="formAttr()"
                            [statut]="eeModel.statut"
                            [initialStatut]="eeModel.initialStatut">
        </app-ee-to-evaluate>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isCompliant()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-compliant [(model)]="eeModel.compliantModel" [ncModel]="eeModel.notCompliantModel" [avModel]="eeModel.toCheckModel"
                          [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                          [editMode]="editMode" [currentSite]="currentSite"  
                          [formAttr]="formAttr()" (onRevision)="onRevision($event)" (onOpportunity)="onOpportunity($event)">
        </app-ee-compliant>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isNotCompliant()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-not-compliant [(model)]="eeModel.notCompliantModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                              [editMode]="editMode"[currentSite]="currentSite" 
                              [formAttr]="formAttr()">
        </app-ee-not-compliant>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isWithoutObject()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-without-object [(model)]="eeModel.withoutObjectModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                               [formAttr]="formAttr()" (onRevision)="onRevision($event)">
        </app-ee-without-object>
      </div>
    </dxi-item>

    <dxi-item [visible]="eeModel.isToCheck()">
      <div *dxTemplate [ngClass]="minimalClassIf()">
        <app-ee-to-check [(model)]="eeModel.toCheckModel" [statut]="eeModel.statut" [initialStatut]="eeModel.initialStatut"
                         [editMode]="editMode"[currentSite]="currentSite" 
                         [formAttr]="formAttr()">
        </app-ee-to-check>
      </div>
    </dxi-item>

    <dxi-item dataField="eeModel.userActions" dataType="[string]" [visible]="false"></dxi-item>

</dx-form>

<!-- Docs -->
<div #docdiv id="docdiv" *ngIf="!minimal">
  <div class="card-body">
    <div class="card-block">
      <div class="card-text">
        <h4 class="card-title">{{'audit-editeur-h4*docs-associes'|translate}}</h4>
      </div>

      <dx-data-grid [disabled]="currentEE.id==0" #pjGrid id="pjGrid" [dataSource]="documentSource"
        height="min-content" [allowColumnResizing]="true" columnResizingMode="nextColumn"
        [wordWrapEnabled]="true" [showRowLines]="true" [showBorders]="true" (onContentReady)="pjGrid_onContentReady($event)"
        (onRowInserting)="pjGrid_onRowInserting($event)">
        <dxo-export [enabled]="true" fileName="pj-eval" [allowExportSelectedData]="true"></dxo-export>
        <dxo-remote-operations [filtering]="true" [paging]="false" [sorting]="false" [grouping]="false">
        </dxo-remote-operations>

        <dxo-selection mode="single"></dxo-selection>

        <dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="true" [allowUpdating]="false">
          <dxo-popup [fullScreen]="false" [title]="'applicables-popup-title*associer-doc'|translate"
            [showTitle]="true" [width]="1000" [height]="500"></dxo-popup>
          <dxo-form [colCount]="1" [labelLocation]="'left'">
            <dxi-item dataField="fichier" [colSpan]="1">
              <dxo-label [text]="'applicables-column-caption*fichier'|translate" alignment="center"></dxo-label>
            </dxi-item>
            <dxi-item dataField="titre" [colSpan]="1"
              [editorOptions]="{placeholder: 'applicables-helpText*titre-doc'|translate}">
              <dxo-label [text]="'applicables-column-caption*titre'|translate"></dxo-label>
            </dxi-item>
          </dxo-form>
        </dxo-editing>

        <dxi-column cssClass="align-cell-vertical" width="160" dataType="date" dataField="dateCreation"
          [caption]="'audit-editeur-popup-form-field*added'|translate"></dxi-column>
        <dxi-column cssClass="align-cell-vertical" dataField="titre"
          [caption]="'audit-editeur-popup-form-field*titre'|translate">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column cssClass="align-cell-vertical" width="90" dataField="extension"
          [caption]="'audit-editeur-popup-form-field*extension'|translate"></dxi-column>
        <dxi-column cssClass="align-cell-vertical" dataField="nomFichier"
          [caption]="'audit-editeur-popup-form-field*nom-fichier'|translate" [visible]="false"></dxi-column>

        <dxi-column cssClass="align-cell-vertical" dataField="rattache_id" [visible]="false"
          [formItem]="{visible: false}"></dxi-column>
        <dxi-column cssClass="align-cell-vertical" dataField="type" [visible]="false"></dxi-column>

        <dxi-column cssClass="align-cell-vertical" width="auto" dataField="fichier"
          editCellTemplate="uploadCellTemplate" caption="Fichier" dataType="string" [allowSorting]="false"
          [visible]="false" [formItem]="{visible: true}">
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <div *dxTemplate="let cell of 'uploadCellTemplate'">
          <app-doc-uploader [container]="cell"></app-doc-uploader>
        </div>


        <dxi-column cssClass="align-cell-vertical" width="140" dataField="id"
          [caption]="'audit-editeur-button*telecharger'|translate" cellTemplate="ddlTemplate">
        </dxi-column>
        <div *dxTemplate="let data of 'ddlTemplate'">
          <dx-button [text]="'audit-editeur-button*telecharger'|translate" type="default"
            (onClick)="downloadDocumentButtonClicked(data.data.id, data.data.nomFichier)"></dx-button>&nbsp;
        </div>
      </dx-data-grid>
    </div>
  </div>


<div *ngIf="showButtons()" [ngClass]="formButtonsClass()">
    <dx-button [text]="'exigence/form-action*save'|translate" type="success" (onClick)="onSubmit($event, false)"></dx-button>

    <dx-button id="saveAllButton" [text]="'exigence/form-action*save-multi'|translate:{'ne': currentReferenceUsage !== undefined ? currentReferenceUsage.eecount : ''}" 
        type="danger"[visible]="showSaveAllButton()" 
        (onClick)="onSubmitAll($event, true)"></dx-button>

        <dx-button id="saveAllActionsButton" [text]="'exigence/form-action*save-multi-actions'|translate:{'ne': parentDataForTransversalActions !== undefined ? parentDataForTransversalActions.length : ''}" 
        type="danger"[visible]="showSaveAllActionsButton()" 
        (onClick)="onSubmitAllForActions($event)"></dx-button>

    <dx-button id="saveAllSiteButton" [text]="'exigence/form-action*save-allsites'|translate" 
        type="danger"[visible]="showSaveAllSiteButton()" 
        (onClick)="onSubmitSiteAll($event, true, false)"></dx-button>
    <dx-button id="saveAllSiteIfButton" [text]="'exigence/form-action*save-allsites-notyetevaluated'|translate" 
        type="danger"[visible]="showSaveAllSiteButton()" 
        (onClick)="onSubmitSiteAll($event, true, true)"></dx-button>

    <dx-button [text]="'exigence/form-action*clear'|translate" type="normal" (onClick)="onCancel($event)"></dx-button> 
</div>