import { HttpHeaders } from '@angular/common/http';
import { confirm } from 'devextreme/ui/dialog';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

// https://docs.google.com/spreadsheets/d/1qOLzq2QckVfsyb6PwXnb_mcnPqQQydQasMgtPhoMnzo/edit#gid=0


export let titreNoeudTaxoReference: { [level: number]: string } = {
  1: "V",
  2: "T",
  3: "S",
  4: "D",
  5: "Sd"
};
export let cssNoeudTaxoReference: { [level: number]: string } = {
  1: "badge  badge-pink fontenoeud",
  2: "badge  badge-blue fontenoeud",
  3: "badge  badge-primary fontenoeud",
  4: "badge  badge-orange fontenoeud",
  5: "badge  badge-info fontenoeud"
};


export let editEEStatutItemsReference: string[] = ["AE", "C", "NC", "SO", "AV"];
export let editEEStatutLabelsReference: any = {
  "AE": "Statut : à évaluer",
  "C": "Statut : conforme",
  "NC": "Statut : non conforme",
  "SO": "Statut : sans objet",
  "AV": "Statut : à vérifier",
  "I": "Rien à évaluer (informatif)",
  "X": "Sélectionner texte & exigence"
};
export let editEEStatutMapReference: any = {
  0: "AE",
  1: "AE",
  2: "C",
  3: "NC",
  4: "SO",
  5: "AV"
};

export let ckconfigtoolbar =
{
  toolbar: [
    { name: 'document', items: ['Source'] },
    { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },
    { name: 'editing', items: ['Find', 'Replace', '-', 'SelectAll', '-', 'Scayt'] },

    { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat'] },
    { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language'] },
    { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
    { name: 'insert', items: ['Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'] },

    { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
    { name: 'colors', items: ['TextColor', 'BGColor'] },
    { name: 'tools', items: ['Maximize', 'ShowBlocks'] }
  ]
};
/*

SiteTypeContratEnum :

AUCUN(0),
	VEILLE_SIMPLE(1),
	VEILLE_COMPLETE(2),
	VEILLE_COMPLETE_AUDIT_SIMPLE(3),
	VEILLE_COMPLETE_AUDIT_STANDARD(4),
  VEILLE_COMPLETE_AUDIT_ISO(5);
  */

export let satelliteInfo: any = [0, "", "", "", "", "", "", ""]
export let userHeaderInfo: any = ["?", "0", 0, 0, 0, 0, "fr", 0, 0]; // user type, user id, lastsite, lastaudit for site, type de contrat associé au site, hotline, dernier langage selectionné, dernier client sélectionné(ctx:groupe), dernier groupe sélectionné
export let comptageTexte: any = [0, 0, 0, 0, 0]; // r.applicables, r.nouveaux, r.modifies, r.abroges, r.futurs
// export let comptageTexte: any = [0, 0, 0, 0, 0, 0]; // r.all, r.applicables, r.nouveaux, r.modifies, r.abroges, r.futurs

let _sqlOperators: { [id: string]: string } = {
  "contains": " LIKE '%{0}%'",
  "startswith": " LIKE '%{0}'",
  "starts": " LIKE '%{0}'",
  "=": " = {1}{0}{1}",
  "!": " NOT ",
  "IS": " IS {0}",
  "ISNOT": " IS NOT {0}",
  "IN": " IN ({0})",
  "notcontains": " NOT IN ({0})",
  ">": " > '{0}'",
  ">=": " >= '{0}'",
  "<": " < '{0}'",
  "<=": " <= '{0}'",
  "<>": " <> '{0}'"

};


let _sqlOperators2: { [id: string]: string } = {
  "contains": " LIKE '%{0}%'",
  "IN": " IN ({0})",
  "startswith": " LIKE '%{0}'",
  "starts": " LIKE '%{0}'",
  "=": " = {1}{0}{1}",
  "!": " NOT ",
  "IS": " IS {0}",
  "ISNOT": " IS NOT {0}",
  "notcontains": " NOT IN ({0})",
  ">": " > '{0}'",
  ">=": " >= '{0}'",
  "<": " < '{0}'",
  "<=": " <= '{0}'",
  "<>": " <> '{0}'"

};

export function sleep(ms) {
  return (new Promise<void>(function (resolve, reject) {
    setTimeout(function () { resolve(); }, ms);
  }));
}


export function getHttpHeaders() {

  let headers =  new HttpHeaders()
   // .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
    .set('Access-Control-Allow-Headers', 'X-Requested-With,content-type')
    .set('Access-Control-Allow-Credentials', "true")
    .set('QSE-utype', [userHeaderInfo[0]])
    .set('QSE-uid', [userHeaderInfo[1]])
    .set('QSE-lang', [sessionStorage.getItem("QSE_LAST_LANGUE")])

    if (sessionStorage.getItem("adminAsUserId")) {
      headers = headers.set('QSE-utype', 'X:' + sessionStorage.getItem("adminAsUserId"))
    }
    
    return headers;
}   

export function stringformat(format: string, ...args: any[]) {
  return format.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined'
      ? args[number]
      : match
      ;
  });
}

export function jsToSQLFilter(filter: Array<any>): string {
  let sql: string = "";
  let leaf: boolean = true;
  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      leaf = false;
      break;
    }
  }
  if (leaf)  // [ "parentId", "=", 0 ]
  // Warning dates will have to be formatted
  {
    var op = _sqlOperators[filter[1]];
   // Cas particulier
    if (filter[2] == "NULL" && filter[1] == "=")    op = " IS {0}";
    if (filter[0] == "reference.dernierHistorique.evenement" && filter[1] == "contains")  op = " IN ({0})";
    if (filter[0] == "reference.dernierHistorique.dateApplicabilite" && filter[1] == ">" && filter[2]=="CURDATE()" )  op = " > {0}";
    if (filter[0] == "n.dateApplicabilite" && filter[1] == ">" && filter[2]=="current_time()" )  op = " > {0}";

    var value = filter[2];
    var escape: boolean = false;
    if (typeof filter[2] === 'string' || filter[2] instanceof String) {
      value = value.replace("'", "''");
      if (value.includes("%")) {
        escape = true;
        value = value.replace("%", "|%");
      }
      // https://stackoverflow.com/questions/5020130/how-to-escape-literal-percent-sign-when-no-backslash-escapes-option-is-enabled
      // where mycol like '5|% off' escape '|';
    }
    


    return (filter[0] + stringformat(op, value, (typeof filter[2] === 'string' || filter[2] instanceof String) ? "'" : "") + (escape ? " escape '|'" : ""));

  }

  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      sql = sql + " (" + jsToSQLFilter(filter[i]) + ") ";
    }
    else
      sql = sql + filter[i] + " ";
  }
  return sql;
}



export function getSatellites() {
  return satelliteInfo[0]
}

export function getSatelliteApiUrl(n:number) {
  if((n+1)>satelliteInfo[0]) return ""
  return satelliteInfo[n+1]
}





export function replaceInFilter(filter: Array<any>, occurrence: string, toreplace: string): string {

  let leaf: boolean = true;
  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      leaf = false;
      break;
    }
  }
  if (leaf) {
    if (filter[0] == occurrence)
      filter[0] = toreplace;
      if (filter[1] == occurrence)
      filter[1] = toreplace;
    return;
  }

  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      replaceInFilter(filter[i], occurrence, toreplace);
    }
    else {
      if (filter[i] == occurrence)
        filter[i] = toreplace;
    }

  }

}




export function replaceDateToISOString(filter: Array<any>): string {

  let leaf: boolean = true;
  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      leaf = false;
      break;
    }
  }
  if (leaf) {
    if (filter[0] instanceof Date)
      filter[0] = filter[0].toISOString();

    if (filter.length > 1 && filter[2] instanceof Date)
      filter[2] = filter[2].toISOString();
    return;
  }

  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      replaceDateToISOString(filter[i]);
    }


  }

}



export function transformStatutInFilter(filter: Array<any>): string {

  let leaf: boolean = true;
  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      leaf = false;
      break;
    }
  }
  if (leaf) {
    if (filter[0] == "statut") {
      if (filter[2]) {
        switch (filter[2]) {
          case 1:
            filter[0] = "e.aevaluer";
            filter[2] = 1;
            break;
          case 2:
            filter[0] = "e.conforme";
            filter[2] = 1;
            break;
          case 3:
            filter[0] = "e.nonconforme";
            filter[2] = 1;
            break;
          case 4:
            filter[0] = "e.sansobjet";
            filter[2] = 1;
            break;
          case 5:
            filter[0] = "e.averifier";
            filter[2] = 1;
            break;
        }
      }

    }
    return;
  }

  for (let i = 0; i < filter.length; i++) {
    if (filter[i] instanceof Array) {
      transformStatutInFilter(filter[i]);
    }


  }
}







export function messageBoxYesNo(message, title, responseHandler, args) {
  var result = confirm(message, title);
  result.done(function (dialogResult) {
    this._messageBoxYesNoResult.resolve(dialogResult, responseHandler, args);
  });
  return this._messageBoxYesNoResult.promise();
}

export function showYesNoConfirmationDialog(title, message) {
  var returnValue = $.Deferred();
  var result = confirm(title, message);
  result.done(function (dialogResult) {
    returnValue.resolve(dialogResult);
  });
  return returnValue.promise();
}

export function scrollInto(id) {
  let x = document.querySelector(id);
  if (x) {
    x.scrollIntoView();
  }
}





export function onExporting(e, sheetname, filename) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(sheetname);

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename+'.xlsx');
    });
  });
  e.cancel = true;
}

