import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { DatePipe } from '@angular/common';
import { jsToSQLFilter, getHttpHeaders } from 'app/services/commons';
import { AppConfig } from 'app/app.config';

let _droitsDataSource = [
  // { value: 0, label: "INACCESSIBLE" },
  { value: 1, label: "LECTURE +mailing" },
  { value: 2, label: "ECRITURE +mailing" },
  { value: 3, label: "LECTURE -mailing" },
  { value: 4, label: "ECRITURE -mailing" },
/*
  { value: 5, label: "LECTURE +mailing +édition profil" },
  { value: 6, label: "ECRITURE +mailing +édition profil" },
  { value: 7, label: "LECTURE -mailing +édition profil" },
  { value: 8, label: "ECRITURE -mailing +édition profil" }
*/
]
// LECTURE_PROFILEDIT(5), ECRITURE_PROFILEDIT(6), LECTURE_NOMAIL_PROFILEDIT(7), ECRITURE_NOMAIL_PROFILEDIT(8);

@Injectable({  providedIn: 'root' })
export class UtilisateurService {

  dataSource: any;


  bcryptallpasswords() {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/bcryptall/", { headers: getHttpHeaders() }).toPromise();
  }

  definirParametre(id: any, clef: string, valeur: string) {
    // Mettre à jour ou créer (pris en charge côté serveur)
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/" + encodeURIComponent(id), { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
  }

  obtenirValeurParametre(clef: string) {
    return this.http.get<any>(AppConfig.settings.api + "/api/parametre/?filter=" + encodeURIComponent(clef), { headers: getHttpHeaders() }).toPromise();
  }

  getUtilisateursPourSitePromise(site_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/accessite/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }


  notifierUtilisateurs(ids: string) {
    return this.http.put<any>(AppConfig.settings.api + "/api/utilisateur/notifier", { ids: ids }, { headers: getHttpHeaders() }).toPromise();
  }

/*
  getUtilisateursPourClientPromise(client_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/client/" + encodeURIComponent(client_id), { headers: getHttpHeaders() }).toPromise();
  }
*/

  getDroitsUtilisateursClientPromise(client_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/access/client/" + encodeURIComponent(client_id), { headers: getHttpHeaders() }).toPromise();
  }
  /*
  correctionDroits() {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/correctiondroits/" , { headers: getHttpHeaders() }).toPromise();
  }
  */

  getUtilisateursPourGroupePromise(groupe_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/groupe/" + encodeURIComponent(groupe_id), { headers: getHttpHeaders() }).toPromise();
  }

  marquerDernierAccesPageAccueilAdmin(admin_id: any) {
    var clef: string = "A" + admin_id   + "_DACCES_ACCUEIL";
    var valeur: string = (new Date()).toISOString().replace("T", " ");
   // sessionStorage.setItem(QSE_LAST_SITE, site_id);
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
  }

  marquerDernierAccesPageSupportAdmin(admin_id: any) {
    var clef: string = "A" + admin_id   + "_DACCES_SUPPORT";
    var valeur: string = (new Date()).toISOString().replace("T", " ");
   // sessionStorage.setItem(QSE_LAST_SITE, site_id);
    return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
  }



marquerDernierAccesPageAccueilClient(user_id: any, site_id: any) {
  var clef: string = "U" + user_id + "S" + site_id + "_DACCES_ACCUEIL";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}

marquerDernierAccesPageSupportClient(user_id: any, site_id: any) {
  var clef: string = "U" + user_id + "S" + site_id + "_DACCES_SUPPORT";
  var valeur: string = (new Date()).toISOString().replace("T", " ");
 // sessionStorage.setItem(QSE_LAST_SITE, site_id);
  return this.http.put<any>(AppConfig.settings.api + "/api/parametre/0", { clef: clef, valeur: valeur }, { headers: getHttpHeaders() }).toPromise();
}




sauvegardeDesDroits(client_id, droits) {
  return this.http.put<any>(AppConfig.settings.api + "/api/utilisateur/droits/client/" +  + encodeURIComponent(client_id), droits, { headers: getHttpHeaders() }).toPromise();
}

verifierExistanceEmail(email) {
  return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/checkmail/" + encodeURIComponent(email), { headers: getHttpHeaders() }).toPromise();
 }

 verifierExistanceUnique(email, euid) {
  return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/checkmailunique/" + encodeURIComponent(email) + "/" + encodeURIComponent(euid), { headers: getHttpHeaders() }).toPromise();
 }

 getUtilisateursPossibles(site_id){
  return this.http.get<any>(AppConfig.settings.api + "/api/utilisateur/horssite/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
 }


  constructor(private http: HttpClient, private datePipe: DatePipe) {

    let SERVICE_URL = AppConfig.settings.api + "/api/utilisateur/";

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any)  : any {

          let filter = "?filter=groupe.id IS NULL";
          if(AppConfig.settings.trace) console.log("UtilisateurService", loadOptions);
          if(AppConfig.settings.trace)console.log("UtilisateurService filter", loadOptions.filter);

          if (loadOptions.filter && loadOptions.searchValue) {
            loadOptions.filter = [loadOptions.filter, "AND", [loadOptions.searchExpr, loadOptions.searchOperation, loadOptions.searchValue]]
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }
          else
          if (loadOptions.filter) {
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }

          if(AppConfig.settings.trace)console.log("UtilisateurService load: calling url: " + SERVICE_URL + filter);

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {

              for (var i = 0; i < response.data.length; i++) {
                var obj = response.data[i];
                obj.fullname = obj.prenom + " " + obj.nom;
              }

              console.log("---------------> Utilisateur SERVER RESPONSE: ", response);
              return response.data

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},



        byKey: function (key) {
          if(AppConfig.settings.trace)console.log("byKey: " + SERVICE_URL + "/" + encodeURIComponent(key));
          return http.get<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise().then(response => {
            response.fullname = response.prenom + " " + response.nom;
            return response;
          }).catch(function (err) {
            console.log(err);
            notify("byKey ERREUR: " + err);
          });
        },





        insert: function (values) {

          // values.dateFin = datePipe.transform(values.dateFin, 'dd-MM-yyyy');
          // values.dateDebut = datePipe.transform(values.dateDebut, 'dd-MM-yyyy');

       //  console.log("CREATION: ", values);

          // Need to create user (password, email)

          if(AppConfig.settings.trace) console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {

            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },
        update: function (key, values) {
          if(AppConfig.settings.trace)console.log("update put: " + SERVICE_URL + "/" + encodeURIComponent(key));
          if(AppConfig.settings.trace) console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          });

        },
        remove: function (key) {
          // Need to refresh cache after that ?
          if(AppConfig.settings.trace) console.log("remove delete: " + SERVICE_URL + "/" + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }



    );

  }

  getRestDataSource() {
    return this.dataSource;
  }


  getDroitDataSource() {
    return _droitsDataSource;
  }

}
