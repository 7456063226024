import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders, replaceInFilter } from 'app/services/commons';
import { AppConfig } from 'app/app.config';
 


let _contratDataSource = [
  { value: 0, label: "AUCUN" },
  { value: 1, label: "VEILLE SIMPLE" },
  { value: 2, label: "VEILLE COMPLÈTE" },
  { value: 3, label: "VEILLE COMPLÈTE AUDIT SIMPLE" },
  { value: 4, label: "VEILLE COMPLÈTE AUDIT STANDARD" },
  { value: 5, label: "VEILLE COMPLÈTE AUDIT ISO" },

]


let _veillesDataSource = [
  { value: 0, label: "V1" },
  { value: 1, label: "V2" },
  { value: 2, label: "V3" },
  { value: 3, label: "V4" },
  { value: 4, label: "V5" },

]


@Injectable({  providedIn: 'root' })
export class SiteService {
  dataSource: any;
  dataSourcePourQuestionnaires: any;
  dataSourcePourAjoutDansProfil : any;
  public currentVeilleAndUsage : any = [0,0];


  getComptesReferencePourSite(site_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/compter/" + encodeURIComponent(site_id), { headers: getHttpHeaders()}).toPromise();
  }

  getCreationsUtilisateurs(site_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/creationsutilisateurs/" + encodeURIComponent(site_id), { headers: getHttpHeaders()}).toPromise();
  }


  getDernierAuditPourSite(site_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/dernieraudit/" + encodeURIComponent(site_id), { headers: getHttpHeaders()}).toPromise();
  }

  verifierActionsNonRealiseesDernierAudit(site_id, lang) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/actionsnonrealiseesdernieraudit/" + encodeURIComponent(site_id) + "?langue="+lang, { headers: getHttpHeaders()}).toPromise();
  }

  getSitesByProfilId(profil_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/byprofil/" + encodeURIComponent(profil_id), { headers: getHttpHeaders()}).toPromise();
  }


  // Retourne un groupe_id
  getGroupeForSite(site_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/groupe/" + encodeURIComponent(site_id), { headers: getHttpHeaders()}).toPromise();
  }


  getSitesPourGroupeSaufPourClient(client_id) {
    return this.http.get<any>(AppConfig.settings.api +"/api/site/groupe/sauf/" + encodeURIComponent(client_id), { headers: getHttpHeaders()}).toPromise();
  }


  diagnoseForSiteToBeMoved(siteids:any, targetclient:any) {
    // Mettre à jour ou créer (pris en charge côté serveur)
   return this.http.get<any>(AppConfig.settings.api + "/api/site/movesitetoclient/diagnose/" +siteids+"/"+targetclient, { headers: getHttpHeaders() }).toPromise();
}

  moveSiteToClient(siteids:any, targetclient:any, options:any ) {
    // Mettre à jour ou créer (pris en charge côté serveur)
   return this.http.put<any>(AppConfig.settings.api + "/api/site/movesitetoclient/" +encodeURIComponent(siteids)+"/"+ targetclient , options, { headers: getHttpHeaders() }).toPromise();
}
verifierVeillesSitesProfils( ) {
  // Mettre à jour ou créer (pris en charge côté serveur)
 return this.http.get<any>(AppConfig.settings.api + "/api/site/verifierveillesprofils" , { headers: getHttpHeaders() }).toPromise();
}


  constructor(private http: HttpClient) {

    let SERVICE_URL = AppConfig.settings.api + "/api/site/";


    var localCurrentVeilleAndUsage=this.currentVeilleAndUsage;
    


    this.dataSource = new CustomStore(
      {
        key: "id",
        load: function (loadOptions: any) : any  { 
          //let postFilter = "client_id=0"
          let postFilter = ""
          var filter = "";
          //   console.log("SiteService ", loadOptions);
             console.log("SiteService filter", loadOptions.filter);
          if (loadOptions.filter) {
            postFilter = jsToSQLFilter(loadOptions.filter);
          }
          if (loadOptions.skip) {
            filter = "?skip=" + loadOptions.skip;
          }
          else
            filter = "?skip=0";

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = "";
              if (loadOptions.sort[i].selector == "nomReference")
                field = "n.reference.nom";
              else
                field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }
          if(AppConfig.settings.trace)  console.log(" SiteService load: calling url: " + SERVICE_URL + filter + " | post:" + postFilter);
          return http.post<any>(SERVICE_URL + "list/" + filter, postFilter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if(AppConfig.settings.trace)   console.log("----- -----> Site SERVER RESPONSE: ", response);
              return response;

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},
        byKey: function (key) {
          if(AppConfig.settings.trace) console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },
        insert: function (values:any) {
          if(AppConfig.settings.trace) console.log("CREATION: ", values);
          if(AppConfig.settings.trace) console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {
            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },
        update: function (key, values) {
          if(AppConfig.settings.trace) console.log("update put: " + SERVICE_URL + encodeURIComponent(key));
          if(AppConfig.settings.trace) console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {
            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          }).catch(function (err) {
            if(err.status==409) 
            notify("ERREUR: profil déjà associé et audit(s) existant(s) : association non permise", "error");
            else
            notify("ERREUR: " + err.statusText + "(" + err.status + ")", "error");
          //  alert("ERREUR: " + err.statusText);
          });

        },
        remove: function (key) {
          // Need to refresh cache after that ?
          if(AppConfig.settings.trace) console.log("remove delete: " + SERVICE_URL + "/" + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }
    );




    // Pour filter par client et par veilleSouscrite
    this.dataSourcePourQuestionnaires = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any) : any  {

          var filter = "?parveille=0,0";

          // filter =  client_id,v1,v2,...
          if (loadOptions.filter) {
            filter = "?parveille=" + encodeURIComponent(loadOptions.filter);
          }

          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if(AppConfig.settings.trace) console.log("---------------> Site__ SERVER RESPONSE: ", response);
              return response;

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
       //   console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }

      }
    );

    this.dataSourcePourAjoutDansProfil = new CustomStore(
      {
        key: "id",
        load: function (loadOptions: any)  : any {
          var filter = "";
         console.log("SiteService: dataSourcePourAjoutDansProfil ", loadOptions);
          //   console.log("SiteService filter", loadOptions.filter);

          if(this.loadOptions) loadOptions = {...loadOptions, ...this.loadOptions} 

          if (loadOptions.filter) {
             
            replaceInFilter(loadOptions.filter, "nomClient", "s.client.nom");
            replaceInFilter(loadOptions.filter, "nomProfil", "s.profil.nom");
            
              filter = localCurrentVeilleAndUsage[0] + "?refusageid=" +  localCurrentVeilleAndUsage[1] + "&filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          }
          else
          filter = localCurrentVeilleAndUsage[0] + "?refusageid="  +  localCurrentVeilleAndUsage[1]+ "&filter=";

          if(loadOptions.isFuture){
            filter = filter + '&isFuture=true'
          }


          if (loadOptions.skip) {
            filter = filter + "&skip=" + loadOptions.skip;
          }
          else
            filter = filter + "&skip=0";

          if (loadOptions.take) {
            filter = filter + "&take=" + loadOptions.take;
          }

          if (loadOptions.sort) {
            var usort = "";
            for (var i = 0; i < loadOptions.sort.length; i++) {
              var field = "";
              if (loadOptions.sort[i].selector == "nomReference")
                field = "n.reference.nom";
              else
                field = loadOptions.sort[i].selector;

              usort = usort + field + " " + (loadOptions.sort[i].desc ? "DESC" : "ASC");
              if (i + 1 < loadOptions.sort.length)
                usort = usort + ",";
            }
            filter = filter + "&sort=" + encodeURIComponent(usort);
          }
          if(AppConfig.settings.trace) console.log(" SiteService:dataSourcePourAjoutDansProfil load: calling url: " +  AppConfig.settings.api + "/api/sites/"  + filter);

          return http.get<any>(AppConfig.settings.api + "/api/sites/" + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if(AppConfig.settings.trace) console.log("---------------> Site:dataSourcePourAjoutDansProfil SERVER RESPONSE: ", response);
              return response;

            });
        }
      }
    );






  }

  getRestDataSource() {
    return this.dataSource;
  }

  getDataSourcePourQuestionnaires() {
    return this.dataSourcePourQuestionnaires;
  }

  getDataSourcePourAjoutDansProfil() {
    return this.dataSourcePourAjoutDansProfil;
  }
  getContratDataSource() {
    return _contratDataSource;
  }

  getVeillesDataSource() {
    return _veillesDataSource;
  }

}
