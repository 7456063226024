import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClientVeilleService } from 'app/client-veille/client-veille.service';
import { EvaluationConformiteService } from 'app/services/evaluation-conformite.service';
import { EvaluationExigenceService } from 'app/services/evaluation-exigence.service';
import { ReferenceUsageService } from 'app/services/reference-usage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit, OnDestroy {

  @Input() audit: any
  @Input() usage: any

  @ViewChild('chart') chart: any

  measureDataSource: any
  initialMeasures: any
  measures: any

  auditSubscription: Subscription
  clientVeilleSubscription: Subscription
  statusSubscription: Subscription

  pieColorPalette = ['#72DB8B', '#FF7878', '#FFA845', 'gray', 'gold', '#82CCFA']

  constructor(private eeService: EvaluationExigenceService, private auditService: EvaluationConformiteService,
    private clientVeilleService: ClientVeilleService) {
    this.measureDataSource = eeService.getReferenceUsageRestDataSource()
   }

  ngOnInit(): void {
    this.auditSubscription = this.auditService.selectedAuditSubject.subscribe(
      (selectedAudit: any) => {
        this.audit = selectedAudit
        this.loadChart()
      }
    )

    this.clientVeilleSubscription = this.clientVeilleService.clientVeilleSubject.subscribe(
      (currentUsage: any) => {
        this.usage = currentUsage
        this.loadChart()
      }
    )

    this.statusSubscription = this.eeService.statusSubject.subscribe(
      (event: any) => {
        this.measures = this.alterChart(event)
        this.chart.instance.refresh()
      }
    )

    this.loadChart()
  }

  ngOnDestroy(): void {
    this.auditSubscription.unsubscribe()
    this.clientVeilleSubscription.unsubscribe()
    this.statusSubscription.unsubscribe()
  }

  loadChart(){
    if(this.audit !== undefined && this.usage !== undefined){
      this.measureDataSource.load({ filter: [['evaluation.id', '=', this.audit], 'and', ['id', '=', this.usage.id]] }).then(
        res => {
          this.measures = res.data[0] !== undefined ? res.data[0].mesures : []
          this.initialMeasures = this.measures
        }
      )
    }
  }

  alterChart(event: any){
    let status = this.getIndex(event.value)
    let previousStatus = this.getIndex(event.previousValue)

    this.measures[status].count = this.measures[status].count + 1
    this.measures[previousStatus].count = this.measures[previousStatus].count - 1
    return this.measures
  }

  getIndex(status: number){
    let index = -1

    switch (status) {
      case 1:
        index = 4
        break;
      case 2:
        index= 0
        break;
      case 3:
        index = 1
        break;
      case 4:
        index = 3
        break;
      case 5:
        index = 2
        break
    }
    return index
  }

}
