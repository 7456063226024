import { Injectable } from '@angular/core';
import CustomStore from "devextreme/data/custom_store";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import notify from 'devextreme/ui/notify';
import { jsToSQLFilter, getHttpHeaders } from 'app/services/commons';
import { AppConfig } from 'app/app.config';

@Injectable({ providedIn: 'root' })
export class ResponsableActionService {


  getSitesForResponsable(responsable_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/sites/" + encodeURIComponent(responsable_id), { headers: getHttpHeaders() }).toPromise();
  }

  getOtherSitesForResponsable(responsable_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/autressites/" + encodeURIComponent(responsable_id), { headers: getHttpHeaders() }).toPromise();
  }

  addSitesToResponsable(responsable_id, sites) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/addsites/" + encodeURIComponent(responsable_id) + "?sites=" + encodeURIComponent(sites), { headers: getHttpHeaders() }).toPromise();
  }

  removeSitesFromResponsable(responsable_id, sites) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/removesites/" + encodeURIComponent(responsable_id) + "?sites=" + encodeURIComponent(sites), { headers: getHttpHeaders() }).toPromise();
  }

  importerUtilisateursCommeResponsables(utilisateurs) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/importusers/?users=" + encodeURIComponent(utilisateurs), { headers: getHttpHeaders() }).toPromise();
  }

  getResponsablesForSite(site_id) {
    return this.http.get<any>(AppConfig.settings.api + "/api/responsable/site/" + encodeURIComponent(site_id), { headers: getHttpHeaders() }).toPromise();
  }

  dataSource: any;
  dataSourceSite: any;
  public sitePredefini: any = [0];

  constructor(private http: HttpClient) {

    let SERVICE_URL = AppConfig.settings.api + "/api/responsable/";
    var predefinedSite = this.sitePredefini;

    this.dataSource = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any): any {

          var filter = "?filter=groupe.id IS NULL";

          if (loadOptions.filter) {

            

            if (AppConfig.settings.trace) console.log("------ResponsableActionService loadOptions.filter --------->", loadOptions.filter);
            filter = "?filter=" + encodeURIComponent(jsToSQLFilter(loadOptions.filter));
          } 


          if (AppConfig.settings.trace) console.log("------ResponsableActionService " + SERVICE_URL + filter);
          return http.get<any>(SERVICE_URL + filter, { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if (AppConfig.settings.trace) console.log("---------------> ResponsableActionService SERVER RESPONSE: ", response);
              return response;

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
          if (AppConfig.settings.trace) console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },
        insert: function (values:any) {

          if (AppConfig.settings.trace) console.log("CREATION: ", values);

          // Need to create user (password, email)

          if (AppConfig.settings.trace) console.log("insert: post " + SERVICE_URL);
          return http.post<any>(SERVICE_URL, values, { headers: getHttpHeaders() }).toPromise().then(response => {

            return {
              values: response.values,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err);
          });
        },
        update: function (key, values) {
          if (AppConfig.settings.trace) console.log("ResponsableActionService update put: " + SERVICE_URL + "/" + encodeURIComponent(key));
          if (AppConfig.settings.trace) console.log("update values:", values);
          return http.put<any>(SERVICE_URL + encodeURIComponent(key), values, { headers: getHttpHeaders() }).toPromise().then(response => {

            notify('Mise à jour effectuée');
            return {
              result: response.result,
              key: response.key
            }
          }).catch(function (err) {
            notify("ERREUR: " + err.statusText + "(" + err.status + ")");
            alert("ERREUR: " + err.statusText);
          });

        },
        remove: function (key) {
          // Need to refresh cache after that ?
          if (AppConfig.settings.trace) console.log("remove delete: " + SERVICE_URL + "/" + encodeURIComponent(key));
          return http.delete<any>(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        }
      }
    );





    this.dataSourceSite = new CustomStore(
      {
        key: "id",

        load: function (loadOptions: any): any {

          if (AppConfig.settings.trace) {
            //  console.log("ResponsableActionService , loadOptions=", loadOptions);
            // console.log("ResponsableActionService"+ SERVICE_URL + "site/" + predefinedSite[0]);

          }
          return http.get<any>(SERVICE_URL + "site/" + predefinedSite[0], { headers: getHttpHeaders() }).toPromise()
            .then(response => {
              if (AppConfig.settings.trace) console.log("---------------> ResponsableActionService SERVER RESPONSE: ", response);
              return response;

            });
        },

        // totalCount: function(loadOptions:any) { return 0;},

        byKey: function (key) {
          if (AppConfig.settings.trace) console.log("byKey: " + SERVICE_URL + encodeURIComponent(key));
          return http.get(SERVICE_URL + encodeURIComponent(key), { headers: getHttpHeaders() }).toPromise();
        },

      }
    );



  }

  getRestDataSource() {
    return this.dataSource;
  }

  getDataSourceSite() {
    return this.dataSourceSite;
  }


}
